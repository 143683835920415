
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "./Firebase";              // DB is store from firstore
import { collection, addDoc, deleteDoc, doc , getDocs ,updateDoc, getDoc, arrayRemove } from "firebase/firestore";

// Adding Data to DB
 export const addData = async (data, collectionName) =>{
  console.log(data.length," come firestore add data line 8")
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    console.log("Document come written with ID: ", docRef.id,collectionName);
  } catch (e) {
    console.error("Error adding document: ", e);
  } 
 }

 // Get data using a custom query
export const getDataWithQuery = async (query) => {
  try {
    console.log(query, "Query ===");
    const querySnapshot = await getDocs(query);
    console.log(querySnapshot, "check2");
    const dataList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return dataList; // Return array of data with document IDs
  } catch (e) {
    console.error("Error fetching documents with query: ", e);
    return [];
  }
};



 //Get data
 export const getData = async (collectionName) => {
  try {
    console.log(collectionName,"CollectionName ===")
    const querySnapshot = await getDocs(collection(db, collectionName));
    console.log(querySnapshot,"check2")
    const dataList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  //  console.log(dataList,"checkdatlist")
    return dataList; // Return array of data with document IDs
  } catch (e) {
    console.error("Error fetching documents: ", e);
    return [];
  }
};

// Delete data
export const deleteData = async(collectionName,id) =>{
  try {
    await deleteDoc(doc(db, collectionName, id));
    console.log("Document successfully deleted",id);
    return { success: true };
  } catch (e) {
    console.error("deleting error")
  }
}

// Function to delete one specific item from the array
export const deleteOneItemFromArray = async (collectionName, docId, itemId) => {
  try {
    const docRef = doc(db, collectionName, docId);

    // Use arrayRemove to remove the specific item
    await updateDoc(docRef, {
      itemList: arrayRemove(itemId),
    });

    console.log(`Item ${itemId} successfully removed from itemList in document ${docId}`);
    return { success: true };
  } catch (error) {
    console.error("Error removing item from array:", error);
    return { success: false, error };
  }
};


// Edit
// export const updateData = async (id, updatedEnteries) => {
//   console.log(id,updatedEnteries,"check1")
//   try {
//     const docRef = doc(db, "Menu", "5gkZ1qY7znJKXZAiflhZ"); // Get the document reference by ID
//     await updateDoc(docRef, updatedEnteries); // Update the document with new fields
//     console.log(`Document with ID: ${id} updated successfully`);
//   } catch (e) {
//     console.error("Error updating document: ", e);
//   }
// };


// export const updateData = async (id, collectionName,updatedEntries) => {
//   console.log(id, updatedEntries, "check1");

//   try {
//     // Create a query to find the document where the 'id' field matches the provided id
//     const menuCollectionRef = collection(db, collectionName);
//     const q = query(menuCollectionRef, where('id', '==', id));

//     // Get the documents that match the query
//     const querySnapshot = await getDocs(q);

//     if (!querySnapshot.empty) {
//       // Assuming there's only one document with that 'id' value
//       const docRef = doc(db, 'Menu', querySnapshot.docs[0].id); // Get the document reference using the doc ID
//       await updateDoc(docRef, updatedEntries); // Update the document with new fields
//       console.log(`Document with ID: ${id} updated successfully`);
//     } else {
//       console.log('No document found with the provided ID');
//     }
//   } catch (e) {
//     console.error("Error updating document: ", e);
//   }
// };


// FirestoreHelper.js
export const getPublishedMenu = async (query) => {
  const snapshot = await getDocs(query);
  const publishedMenus = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  console.log(publishedMenus,"publishedMenus")
  return publishedMenus;
};


// Edit
export const updateData = async (id, collectionName, updatedEnteries) => {
  try {
    const docRef = doc(db,collectionName, id); // Get the document reference by ID
    await updateDoc(docRef, updatedEnteries); // Update the document with new fields
    console.log(`Document with ID: ${id} updated successfully`);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

// Upload image 
export const uploadImageToFirebase = async (file, path = "/") => {
  if (!file) throw new Error("No file provided");

  try {
      // Create a reference with the original file name
      const filePath = `${path}${file.name}`;
      console.log("come file", filePath)
      const storageRef = ref(storage, filePath);
      console.log("come storageRef", storageRef)


      // Upload the file
      const snapshot = await uploadBytes(storageRef, file);
      console.log("come snapshot", snapshot)
      
      // Get the download URL
      const downloadUrl = await getDownloadURL(snapshot.ref);
      console.log("come downloadUrl", downloadUrl)

      return downloadUrl; // Return the URL for further use
  } catch (error) {
      console.error("Error uploading image:", error);
      throw error; // Re-throw to handle it in the calling code
  }
};
export const deleteComboByIndex = async (dbName,docId, indexToRemove) => {
  try {
    // Step 1: Get the document
    const docRef = doc(db, dbName, docId); // Replace "yourCollection" with your collection name
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      // Step 2: Remove the specific index from the array
      const updatedComboList = data.comboList.filter((_, index) => index !== indexToRemove);

      // Step 3: Update the document
      await updateDoc(docRef, { comboList: updatedComboList });

      console.log("Combo removed successfully!");
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error deleting combo: ", error);
  }
};

// Pagination Helper
// export const getPaginatedData = async (
//   collectionName,
//   pageSize,
//   lastVisibleDoc = null,
//   orderByField = "created_date",
//   orderDirection = "desc"
// ) => {
//   try {
//     if (!pageSize || pageSize <= 0) {
//       throw new Error("Page size must be greater than zero.");
//     }

//     const collectionRef = collection(db, collectionName);

//     // Build query with dynamic ordering and limit
//     let q = query(collectionRef, orderBy(orderByField, orderDirection), limit(pageSize));

//     // If lastVisibleDoc is provided, fetch the next set of documents starting after it
//     if (lastVisibleDoc) {
//       q = query(q, startAfter(lastVisibleDoc));
//     }

//     const snapshot = await getDocs(q);

//     // Map documents to a usable data structure
//     const data = snapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));

//     // Get the last visible document for pagination
//     const newLastVisibleDoc = snapshot.docs[snapshot.docs.length - 1] || null;

//     return { data, newLastVisibleDoc };
//   } catch (error) {
//     console.error(`Error fetching paginated data for collection "${collectionName}":`, error);
//     throw error;
//   }
// };

// publsidh status
// export const fetchPublishStatus = async (date, eatTime) => {
//   if (!date || !mealType) return false; // Return false if parameters are missing

//   try {
//     const docId = `${date}_${mealType}`;
//     const docRef = db.collection("PublishedMenu").doc(docId);
//     const doc = await docRef.get();

//     if (doc.exists) {
//       console.log("Document data:", doc.data());
//       return doc.data()?.isPublishFinal || false; // Return the isPublished field or false
//     } else {
//       return false; // Default to not published if no document exists
//     }
//   } catch (error) {
//     console.error("Error fetching publish status:", error);
//     return false; // Handle errors by returning false
//   }
// };

// export const fetchPublishStatus = async (date, eatTime) => {
//   if (!date || !eatTime) return false; // Return false if parameters are missing

//   try {
//     const docId = `${date}_${eatTime}`; // Construct document ID
//     const docRef = doc(db, "PublishedMenu", docId); // Use modular syntax
//     const docSnap = await getDoc(docRef); // Fetch document snapshot

//     if (docSnap.exists()) {
//       console.log("Document--- data:", docSnap.data());
//       return docSnap.data()?.isPublished || false; // Return the 'isPublishFinal' field or false
//     } else {
//       console.log("No document found for the given date and meal type");
//       return false; // Default to not published if no document exists
//     }
//   } catch (error) {
//     console.error("Error fetching publish status:", error);
//     return false; // Handle errors by returning false
//   }
// };