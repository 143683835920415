import React from "react";
import "./styles/TermsAndConditions.css";

const ShippingPolicy = () => {
  return (
    <div className="terms-container">
      <h1>Shipping and Delivery Policy</h1>

      <h5>1. Order Processing</h5>
      <p>
        Our Operating hours are, Lunch - 11:00am to 02:00pm and Dinner - 06:00pm
        to 09:00pm Delivery orders are to be placed before the cut off time,
        Lunch - 10:30am, Dinner - 05:30pm for the same day delivery
      </p>

      <p>
        2. We only accept orders through our website/app and do not process
        phone or email orders unless otherwise stated. Delivery Areas We
        currently deliver to customers within the radius range of 3kms from our
        outlets. For our current locations kindly refer to the Locations on the
        website. If your location is not within our standard delivery zone,
        please contact us at 9606 455 677 / info@gamagamaa.in to check the
        availability .
      </p>

      <h5>3. Delivery Timeframe</h5>
      <p>
        Standard Delivery: Orders are typically delivered between, Lunch -
        11:00am to 01:00pm and Dinner - 06:00pm to 08:00pm. Scheduled Delivery:
        Currently we do not have an option to choose a delivery slot, we shall
        update you on the changes if any in the future. Bulk Orders: As we cook
        our products fresh and in limited quantity, we only accept bulk order
        (above Rs.1000/-) with 24 hours of prior notice. Bulk order placed for
        the same day delivery is subject to fulfilment.
      </p>

      <h5>4. Delivery Charges</h5>
      <p>
        Currently we do not levy a delivery charge, we shall update you of
        changes if any in the future.
      </p>

      <h5>5. Minimum delivery order value</h5>
      <p>There is a minimum delivery order value of Rs.100/- is applicable.</p>

      <h5>6. Freshness and Packaging</h5>
      <p>
        All food items are packed in a tamper-proof packaging to ensure
        freshness and hygiene.
      </p>

      <h5>7. Delivery Partner & Tracking</h5>
      <p>
        We use our own delivery riders, currently we do not have a delivery
        rider tracking option.
      </p>
      <h5>8. Failed Delivery Attempts</h5>
      <p>
        If the recipient is unavailable at the provided address, we will attempt
        to contact them. If the order is undeliverable due to incorrect
        address/contact details/the customer is not reachable or responding,
        Gama Gamaa shall retry delivering the order and also has the right to
        cancel the order.
      </p>
    </div>
  );
};

export default ShippingPolicy;
