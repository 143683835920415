import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from "reactstrap";
import { addData } from "../services/FirestoreHelper";

const UnsubscribeModal = ({ isOpen, toggle }) => {
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = async () => {
  
    try {
       const data = await addData({UnsubscriberEmail:email}, "Unsubscribed"); 
        console.log("Data added successfully:", data);
    } catch (error) {
      console.log("Error in Unsubscribing:", error);
      alert("Error in Unsubscribing. Please try again later.");
        
    }
    console.log("Unsubscribed:", email);
    // Handle Unsubscribe Logic Here
    toggle();
    setEmail("")
    setIsChecked("")
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle} style={{backgroundColor:"#8134af", color:"white"}}>Tell us if you wish to unsubscribe and remove your account</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="email">Enter Email or Username</Label>
          <Input
            type="text"
            id="email"
            placeholder="Enter your email or username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup check>
          <Input
            type="checkbox"
            id="confirmDelete"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <Label for="confirmDelete" check>
            I confirm the deletion of my account.
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleConfirm} disabled={!email || !isChecked}>
          Confirm Unsubscribe
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UnsubscribeModal;
