import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDvd2zTzCJAF0HsLTRhYUyXdr-GWQfFRJM",
    authDomain: "gamagaama-6c9e7.firebaseapp.com",
    projectId: "gamagaama-6c9e7",
    storageBucket: "gamagaama-6c9e7.firebasestorage.app",
   // storageBucket: "gamagaama-6c9e7.appspot.com", //
    messagingSenderId: "731268752389",
    appId: "1:731268752389:web:c7cf65f683d84fced0e86c",
    measurementId: "G-NPT7GMFW6P"
  };
  // Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app)

auth.languageCode = 'en';

export {app,auth,db,storage}


// Prod

