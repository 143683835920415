import React, { useState, useEffect } from "react";
import { Carousel, CarouselItem, Container, Button, Row, Col } from "reactstrap";
import "./styles/SpecialtiesCarousel.css";

const specialties = [
  { id: 1, name: "Beans usili", img: "https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FscoreFiles%2FBeans%20usili.webp?alt=media&token=1eb12606-ab0c-4acb-ab6e-f1b7ea937df1"},
  { id: 2, name: "Idiyappam", img: "https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FscoreFiles%2FIdiyappam.webp?alt=media&token=44e2ac17-3c17-41d7-a12f-03286e644778"},
  { id: 3, name: "Keerai kootu", img: "https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FscoreFiles%2FKeerai%20kootu.webp?alt=media&token=22a8aabc-47e1-4955-bc03-3726d0fd70ce"},
  { id: 4, name: "Sambar", img: "https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FscoreFiles%2FSambar.webp?alt=media&token=a2cdedc8-7b3f-4934-aec5-8cafbae6df9e"},
  { id: 5, name: "Siru urulai roast", img: "https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FscoreFiles%2FSiru%20urulai%20roast.webp?alt=media&token=488140f8-b24a-4271-9e28-96a9196c08d2"},
  { id: 6, name: "Tamarind rice", img: "https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FscoreFiles%2FTamarind%20rice.webp?alt=media&token=99c0fc09-8007-49d3-adcb-30fa37dfac32"}
];

const chunkArray = (array, size) => {
  return array.reduce((acc, _, index) => 
    index % size === 0 ? [...acc, array.slice(index, index + size)] : acc, []
  );
};

const SpecialtiesCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [itemsPerSlide, setItemsPerSlide] = useState(3); // Default for web

  // Adjust itemsPerSlide based on screen width
  useEffect(() => {
    const updateItemsPerSlide = () => {
      setItemsPerSlide(window.innerWidth < 768 ? 1 : 3); // 1 for mobile, 3 for web
    };

    updateItemsPerSlide(); // Initial check
    window.addEventListener("resize", updateItemsPerSlide);

    return () => window.removeEventListener("resize", updateItemsPerSlide);
  }, []);

  const slides = chunkArray(specialties, itemsPerSlide);

  const next = () => {
    if (animating) return;
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prev = () => {
    if (animating) return;
    setActiveIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 3000); // Auto-slide every 3 seconds
    return () => clearInterval(interval);
  }, [activeIndex]); // Run effect when slide length changes

  return (
    <div id="specialties" className="specialties-section">
      <Container className="text-center">
        <h2 className="specialties-title">Our Specialties</h2>
        <Carousel activeIndex={activeIndex} next={next} previous={prev} className="specialties-carousel">
          {slides.map((slide, index) => (
            <CarouselItem key={index} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
              <Row className="carousel-row">
                {slide.map((item) => (
                  <Col md={itemsPerSlide === 1 ? 12 : 4} key={item.id}> {/* Full width for mobile */}
                    <img src={item.img}  alt={item.name} className="carousel-image" loading="lazy" />
                    <p className="dish-name">{item.name}</p>
                  </Col>
                ))}
              </Row>
            </CarouselItem>
          ))}
        </Carousel>
        <a href="#download"  > <Button className="order-button">Order Now</Button></a>
      </Container>
      <div className="carousel-controls">
        <Button className="prev-btn mx-5" onClick={prev}>❮</Button>
        <Button className="next-btn mx-5" onClick={next}>❯</Button>
      </div>
    </div>
  );
};

export default SpecialtiesCarousel;
