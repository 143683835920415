import React from "react";
import { Container } from "reactstrap";
import "./styles/AboutUs.css";

const AboutUs = () => {
  return (
    <div id="about" className="about-section">
      <Container>
        <h2 className="about-title">About Us</h2>
        <p className="about-text">
          Gama Gamaa brings you everyday meals for lunch and dinner, with an
          array of vegetarian dishes that are homely, tasty, nourishing and
          affordable. At Gama Gamaa, we understand that you do not always have
          the time and resources to cook for yourself, but still want the
          goodness and authenticity of home-cooked meals. That's why we bring
          you balanced meals made from high quality ingredients, cooked the
          traditional way.
        </p>
        <br />
        <p className="about-text">
          All dishes are cooked fresh for every meal, with spice blends and
          condiments made in-house, with no additives or enhancers. Daily
          changing menus allow you to eat from Gama Gamaa through the week, and
          always order new dishes and combinations.
        </p>
      </Container>
    </div>
  );
};

export default AboutUs;
