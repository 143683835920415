import React from 'react'
import CustomNavbar from './NavBar'
import HeroSection from './HeroSection'
import AboutUs from './AboutUs'
import SpecialtiesCarousel from './SpecialtiesCarousel'
import Location from './Location'
import Story from './Story'
import Footer from './Footer'
import DownloadOurApp from './DownloadOurApp'

function website() {
  return (
    <div>
      <CustomNavbar />
      <HeroSection/>
      <AboutUs link />
      <SpecialtiesCarousel />
      {/* <Story /> */}
      <Location />
      <DownloadOurApp />
      <Footer />
    </div>
  )
}

export default website