import React from "react";
import { Col, Container, Row } from "reactstrap";
import "./styles/DownloadOurApp.css";
function DownloadOurApp() {
  return (
    <div id="download" className="download-app">
      <h2>Download Our App on Android & iOS to Order Now!</h2>
      <div className="app-buttons">
        <Row>
          <Col md="6 my-2">
          <a title="Coming Soon" style={{cursor:"pointer"}} >
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/256px-Google_Play_Store_badge_EN.svg.png"  alt="Google Play" loading="lazy"/>
        </a>
          </Col>
          <Col md="6 my-2">
          <a title="Coming Soon" style={{cursor:"pointer"}} >
          <img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="App Store" loading="lazy" />
        </a>
          </Col>
        </Row>
        
      
      </div>
    </div>
  );
}

export default DownloadOurApp;
