import React, { useState } from "react";
import { Navbar, Nav, NavItem, NavLink, Button, Container, NavbarToggler, Collapse } from "reactstrap";
import { ReactComponent as AppLogo } from "../assets/Logo.svg";
import "./styles/NavBar.css";

const CustomNavbar = () => {
  const [isOpenCollapseMenu, setIsOpenCollapseMenu] = useState(false);
  const toggleCollapseMenu = () => setIsOpenCollapseMenu(!isOpenCollapseMenu);

  return (
    <>
      {/* Main Navbar */}
      <Navbar expand="md" className="custom-navbar">
        <Container className="navbar-container">
          <AppLogo className="navbar-logo" />
          {/* <NavbarToggler onClick={toggleCollapseMenu} /> */}
          {/* Hamburger / Close Icon Toggle */}
      <NavbarToggler onClick={() => setIsOpenCollapseMenu(!isOpenCollapseMenu)} className="menu-icon">
        {isOpenCollapseMenu ? "✕" : "☰"}
      </NavbarToggler>
          {/* Desktop Nav Links (Visible in Desktop Mode) */}
          <Nav className="nav-links d-none d-md-flex">
            <NavItem>
              <NavLink href="#about">About Us</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="#story">Our Story</NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink href="#specialties">Our Specialties</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#locations">Locations</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#contact">Contact Us</NavLink>
            </NavItem>
            <NavItem>
          <NavLink href="#download"> <Button className="order-btn">Order On App!</Button></NavLink>
          </NavItem>
          </Nav>
        </Container>
      </Navbar>

      {/* Mobile Menu (Dropdown Below Navbar) */}
      <Collapse isOpen={isOpenCollapseMenu} navbar className="mobile-dropdown d-md-none">
        <Nav className="nav-links">
          <NavItem>
            <NavLink href="#about">About Us</NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink href="#story">Our Story</NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink href="#specialties">Our Specialties</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#locations">Locations</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#contact">Contact Us</NavLink>
          </NavItem>
          <NavItem>
          <NavLink href="#download"> <Button className="order-btn">Order On App!</Button></NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </>
  );
};

export default CustomNavbar;
