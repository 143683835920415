import React from "react";
import "./styles/Location.css";

function Location() {
  return (
    <div id="locations" className="location-section">
      <h2 className="location-title">Location</h2>
      <div className="location-container">
        <picture>
          {/* Mobile image (up to 768px) */}
          <source 
            media="(max-width: 768px)" 
            srcSet="https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FscoreFiles%2FNewGamaLocation%20(1).webp?alt=media&token=ad9683b3-e9d2-4ecc-9762-b8b63eefe8a4" 
          />
          {/* Default image (desktop) */}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/gamagaama-6c9e7.firebasestorage.app/o/webSite%2FNewAvifFormat%2FNewGamaLocation.avif?alt=media&token=da9fa7b5-1395-43a3-8bad-3e306cf6ae10"
            alt="map"
            className="map"
            loading="lazy"
          />
        </picture>
      </div>
    </div>
  );
}

export default Location;
