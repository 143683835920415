import React, { useState } from "react";
import UnsubscribeModal from "./unsubscribeModal.js";
import "./styles/TermsAndConditions.css";
const TermsAndConditions = () => {
  const [isOpenUnsubscribeModal, setIsOpenUnsubscribeModal] = useState(false);
  const toggleUnsubscribeModal = () =>
    setIsOpenUnsubscribeModal(!isOpenUnsubscribeModal);

  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to <a href="https://www.gamagamaa.in">www.gamagamaa.in</a>. If
        you continue to browse and use this website, you are agreeing to comply
        with and be bound by the following terms and conditions of use, which
        together with our privacy policy govern Gama Gamaa's relationship with
        you in relation to this website. If you disagree with any part of these
        terms and conditions, please do not use our website.
      </p>
      <p>
        The term 'Gama Gamaa' or 'us' or 'we' refers to the owner of the website
        whose registered headquarters is in No.13 Varadhani Complex, Bellandur,
        Sarjapur Outer Ring Road, Bangalore 560103, India. The term 'you' refers
        to the user or viewer of our website.
      </p>
      <h2>Terms of Use</h2>
      <ul>
        <li>
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this website for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
        </li>
        <li>
          Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this website meet your specific
          requirements.
        </li>
        <li>
          This website contains material which is owned by or licensed to us.
          This material includes, but is not limited to, the design, layout,
          look, appearance, and graphics. Reproduction is prohibited other than
          in accordance with the copyright notice, which forms part of these
          terms and conditions.
        </li>
        <li>
          All trademarks reproduced in this website which are not the property
          of, or licensed to, the operator are acknowledged on the website.
        </li>
        <li>
          Unauthorized use of this website may give rise to a claim for damages
          and/or be a criminal offense.
        </li>
        <li>
          From time to time this website may also include links to other
          websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the
          website(s). We have no responsibility for the content of the linked
          website(s).
        </li>
        <li>
          Your use of this website and any dispute arising out of such use of
          the website is subject to the laws of India.
        </li>
        <li>
          If you wish to delete your account, please{" "}
          <a
            onClick={toggleUnsubscribeModal}
            style={{ cursor: "pointer", color: "blue" }}
          >
            click here
          </a>{" "}
          to submit a request.
        </li>
      </ul>
      <UnsubscribeModal isOpen={isOpenUnsubscribeModal} toggle={toggleUnsubscribeModal} />
    </div>
  );
};

export default TermsAndConditions;
