import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "./styles/HeroSection.css";

const HeroSection = () => {

  return (
    <div className="hero-section">
      <Container>
        <Row>
          <Col md={8}>
            <h1 className="hero-title">
            Homely Food, <br/>Healthy Life 
            </h1>
            {/* <p className="hero-subtitle"> 
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.
            </p> */}
           <a href="#download"  > <Button className="hero-btn"  >Order Now</Button></a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
