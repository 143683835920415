import React from "react";
import "./styles/TermsAndConditions.css";

const PrivacyAndPolicy = () => {
  return (
    <div className="terms-container">
      <h1>Privacy Policy</h1>
      <p>
        This website is owned and operated by Pearltri Foods Pvt. Ltd. We are
        committed to protecting the privacy of our visitors while they interact
        with the content, products, and services on this site (the Site). This
        Privacy Policy applies to the Site only. It does not apply to other
        websites to which we link. Because we gather certain types of
        information about our users, we want you to understand what information
        we collect about you, how we collect it, how that information is used,
        and how you can control our disclosure of it. You agree that your use of
        the Site signifies your assent to this Privacy Policy. If you do not
        agree with this Privacy Policy, please do not use the Website and App.
      </p>

      <h5>1. Information Collected</h5>
      <p>We collect two types of information from you:</p>
      <ul>
        <li>Information that you voluntarily provide to us.</li>
        <li>Information derived through automated tracking mechanisms.</li>
      </ul>

      <h5>a. Voluntary Registration Information</h5>
      <p>
        In order to fully access this website, you must first complete the
        registration (signup) process, during which we will collect personal
        information about you. The information may include your name, address,
        email address, Facebook ID, phone. We do not collect personally
        identifiable information about you except when you specifically provide
        such information to us on a voluntary basis. By registering with us, you
        consent to the use and method of disclosure as described in this Privacy
        Policy.
      </p>

      <h5>b. Voluntary Information for Services and Features</h5>
      <p>
        We may also collect personally identifiable information when you choose
        to use certain other features of the Site, including: i) making
        purchases, ii) consenting to receive email or text messages about
        upcoming promotions or events. When you choose to use these additional
        features, we require you to provide your information that may be
        required to complete a transaction such as your phone number, billing
        and shipping addresses and credit card information. Occasionally, we may
        also request information such as your shopping preferences and
        demographics which will help us better serve you and our others users in
        the future
      </p>

      <h5>c. Cookies</h5>
      <p>
        Our site uses "cookies" and other tracking technologies. Cookies enable
        us to serve secure pages to our users without asking them to sign in
        repeatedly. Most browsers allow you to control cookies, including
        whether or not to accept them and how to remove them. If a user's system
        is idle for a defined time, the cookie will expire, forcing the user to
        sign in again to continue their session. This prevents unauthorized
        access to the user's information while they are away from their
        computer. You may set most browsers to notify you if you receive a
        cookie, or you may choose to block cookies with your browser, but please
        note that if you choose to erase or block your cookies, you will need to
        re-enter your original user ID and password to gain access to certain
        parts of the Site.
        <br />
        Third-Party Cookies: In the course of serving advertisements to this
        site, our third-party advertisers may place or recognize a unique
        "cookie" on your browser.
      </p>

      <h5>3. How We Use Your Information</h5>
      <p>
        Pearltri Foods Pvt. Ltd only uses your personal information for the
        original purposes it was given. Pearltri Foods Pvt.Ltd will not
        disclose, use, transfer, give or sell any personal information to third
        parties for any purposes other than to our suppliers and other third
        parties who need to know in order to deliver services on behalf of
        Pearltri Foods Pvt.Ltd unless required to do so by law. Further,
        Pearltri Foods Pvt.Ltd reserves the right to contact you regarding
        matters relevant to the underlying service provided and/or the
        information collected. Please note that personally identifiable
        information is used only to provide you with a more enjoyable,
        convenient online experience and to help us identify and/or provide
        information, products or services that may be of interest to you. We use
        your personally identifiable information to support and enhance your use
        of the Site and its features, including without limitation: fulfilling
        your order; providing customer service; tracking email invitations you
        send; and otherwise supporting your use of the Site. Pearltri Foods
        Pvt.Ltd may use your personal information for target advertising towards
        you based on things such as region, gender, interests, goals, habits,
        etc. This is collected in order to better understand our website usage,
        and enhance the performance of services to maintain and operate the site
        and certain features on the website. and App. We may transfer personally
        identifiable information as an asset in connection with a proposed or
        actual merger or sale (including any transfers made as part of an
        insolvency or bankruptcy proceeding) involving all or part of our
        business or as part of a corporate reorganization, stock sale or other
        change in control. Pearltri Foods Pvt.Ltd may disclose Contact
        Information in special cases where we have reason to believe that
        disclosing this information is necessary to identify, contact or bring
        legal action against someone who may be violating our terms and
        conditions of use or may be causing injury or interference with our
        rights, property, our customers or anyone who could be harmed by such
        activities. WE ARE NOT LIABLE OR RESPONSIBLE FOR THE PERSONALLY
        IDENTIFIABLE OR OTHER INFORMATION YOU CHOOSE TO SUBMIT IN PUBLICLY
        ACCESSIBLE AREAS OF THE SITE, INCLUDING BUT NOT LIMITED TO FORUMS,
        BULLETIN BOARD, CHAT ROOM, OR ANY OTHER PUBLICLY SECTION OF THE SITE.
        You will receive notice when your personally identifiable information
        might be provided to any third party for any reason other than as set
        forth in this Privacy Policy, and you will have an opportunity to
        request that we do not share such information. We use non-identifying
        and aggregate information to better design our website and for business
        and administrative purposes. We may also use or share with third parties
        for any purpose aggregated data that contains no personally identifiable
        information.
      </p>

      <h5>4. How We Protect Your Information</h5>
      <p>
        We are committed to protecting the information we receive from you. We
        take reasonable security measures to protect your information against
        unauthorized access to or unauthorized alteration, disclosure or
        destruction of data. To prevent unauthorized access, maintain data
        accuracy, and ensure the correct use of information, we maintain
        reasonable physical, electronic, and managerial procedures that are
        designed to safeguard and secure the information and data stored on our
        system. While no computer system is completely secure, we believe the
        measures we have implemented are designed to reduce the likelihood of
        security problems to a level appropriate to the type of data involved.
      </p>

      <h5>5. Accessing and Updating Your Information</h5>
      <p>
        We provide mechanisms for updating and correcting your personal
        information for many of our services. If you are a registered user, you
        may access and update your registration information and your preferences
        to receive email or other communications from us by sending an email to
        info@gamagamaa.in.
      </p>

      <h5>6. Email Choice/Opt-out</h5>
      <p>
        If any user who receives an email from the Site and would prefer not to
        receive such communications in the future, the user can opt-out by
        following the instructions in the emails. In addition, you may send an
        email to info@gamagamaa.in. We will make commercially reasonable efforts
        to implement your opt-out requests promptly, but you may still receive
        communications from us for up to ten business days as we process your
        request.
      </p>

      <h5>7. Children's Privacy and Parental Controls</h5>
      <p>
        We do not solicit any personal information from children. If you have
        not completed 18 years, you are not authorized to use the Site.
      </p>
      <h5>8. Disclaimer to Security</h5>
      <p>
        By consenting to the Terms and Conditions of the Site and hence the
        Privacy Policy, you consent that no data transmission over the Internet
        is completely secure. We cannot guarantee or warrant the security of any
        information you provide to us and you transmit such information to us at
        your own risk.
      </p>
      <h5>9. Notification of Changes</h5>
      <p>
        Pearltri Foods Pvt. Ltd reserves the right to modify this Privacy Policy
        at any time. Continued use of the Site indicates acceptance of any
        changes.
      </p>
    </div>
  );
};

export default PrivacyAndPolicy;
